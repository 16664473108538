import React from 'react';
import { defineMessages } from 'react-intl';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  text: {
    id: 'Text',
    defaultMessage: 'Text',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    properties: {
      text: {
        title: intl.formatMessage(messages.text),
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.item),
        fields: ['text'],
      },
    ],
    required: ['text'],
  };
};

const Widget = (props) => {
  const itemSchema = ItemSchema(props.intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default Widget;
