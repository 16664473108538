import { defineMessages, useIntl } from 'react-intl';
import Carousel from 'react-multi-carousel';
import { Icon as VoltoIcon } from '@plone/volto/components';
import OfferCard from './OfferCard';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';
import './carousel.less';

const messages = defineMessages({
  previous: {
    id: 'Previous',
    defaultMessage: 'Previous',
  },
  next: {
    id: 'Next',
    defaultMessage: 'Next',
  },
});

const CustomLeftArrow = ({ onClick, intl }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      aria-label={intl.formatMessage(messages.previous)}
    >
      <VoltoIcon name={arrowSVG} className="link" size="64px" />
    </button>
  );
};

const CustomRightArrow = ({ onClick, intl }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      aria-label={intl.formatMessage(messages.next)}
    >
      <VoltoIcon name={arrowSVG} className="link" size="64px" />
    </button>
  );
};

const CustomButtonGroupAsArrows = ({ next, previous, carouselState }) => {
  const intl = useIntl();
  return (
    <>
      {carouselState.currentSlide > 0 && (
        <CustomLeftArrow onClick={previous} intl={intl} />
      )}
      {carouselState.currentSlide + carouselState.slidesToShow <
        carouselState.totalItems && (
        <CustomRightArrow onClick={next} intl={intl} />
      )}
    </>
  );
};

const OffersCarousel = ({ offers, rehydrateOffers = false }) => {
  return (
    <div className="offers-carousel">
      <Carousel
        autoPlay={false}
        shouldResetAutoplay={false}
        responsive={{
          desktop: {
            breakpoint: { max: 9999999, min: 1280 },
            items: 3,
            partialVisibilityGutter: 0,
          },
          tablet: {
            breakpoint: { max: 1280, min: 768 },
            items: 2,
            partialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            partialVisibilityGutter: 90,
          },
        }}
        ssr={true}
        infinite={offers.length > 2}
        arrows={false}
        renderButtonGroupOutside
        partialVisible={offers.length > 1}
        customButtonGroup={
          offers.length > 1 ? <CustomButtonGroupAsArrows /> : <></>
        }
      >
        {offers.map((offer) => (
          <OfferCard
            content={offer}
            compact={true}
            key={offer['@id']}
            rehydrate={rehydrateOffers}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default OffersCarousel;
