/* 
  Extend original Form component to display prev- / next-button.
*/
import { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Icon } from 'semantic-ui-react';
/* eslint-disable-next-line import/no-unresolved */
import FormOrig from '@plone/volto-original/components/manage/Form/Form';

const conditionallyShowField = (fieldId, condition) => {
  const els = document.getElementsByClassName(`field-wrapper-${fieldId}`);
  if (els.length === 0) return;
  let el = els[0];
  if (el.parentElement.classList.contains('objectlist-widget'))
    el = el.parentElement;
  if (el) el.style.display = condition ? 'block' : 'none';
};

const conditionallyMarkFieldAsRequiredToPublish = (fieldName, condition) => {
  const el = document.querySelector(`.field-wrapper-${fieldName} label`);
  const elMarker = document.querySelector(
    `.field-wrapper-${fieldName} label .required-to-publish-marker`,
  );
  if (condition) {
    if (!elMarker && el) {
      const newMarker = document.createElement('span');
      newMarker.classList.add('required-to-publish-marker');
      newMarker.title = 'Erforderlich, um Angebot veröffentlichen zu können';
      el.appendChild(newMarker);
    }
  } else {
    if (elMarker) elMarker.remove();
  }
};

/* using `forwardRef` to keep  existing refs intact
   (for example save button in sidebar uses a ref to the form)
*/
const Form = forwardRef((props, ref) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [requiredFieldsToPublish, setRequiredFieldsToPublish] = useState(false);

  const formData = useSelector((state) => state.form.global);

  const isRelevantContentType =
    ['Offer', 'Umbrella Offer'].indexOf(props.type) > -1;

  const updateConditionalFields = () => {
    if (isRelevantContentType) {
      conditionallyShowField(
        'curriculum',
        formData.is_learning_pathway || props.type === 'Offer',
      );
      conditionallyShowField('learning_pathway', formData.is_learning_pathway);
      conditionallyShowField(
        'offer_turnus_other',
        formData.offer_turnus === 'other',
      );
      conditionallyShowField(
        'offer_kind_other',
        formData.offer_kind === 'other',
      );
      conditionallyShowField(
        'creditability_description',
        formData.creditability?.length > 0,
      );
      conditionallyShowField(
        'creditability_achievements',
        formData.creditability?.length > 0,
      );
      conditionallyShowField(
        'certificates',
        /* When entering the edit form `formData.creditability` is an
           array of objects like { title: 'Title 1', token: 'token1' }.
           When field was edited, it turns into array of tokens.
           So we have to cover both cases here :)
        */
        (formData.creditability || []).indexOf('badge') > -1 ||
          (formData.creditability || [])
            .filter((c) => c.token)
            .map((c) => c.token)
            .indexOf('badge') > -1,
      );
      conditionallyShowField(
        'learning_outcomes_intro',
        !formData.learning_outcomes_in_sub_offers,
      );
      conditionallyShowField(
        'learning_objectives',
        !formData.learning_outcomes_in_sub_offers,
      );
      conditionallyMarkFieldAsRequiredToPublish(
        'creditability',
        formData?.is_learning_pathway || false,
      );
    }
  };

  useEffect(() => {
    if (__CLIENT__) {
      updateConditionalFields();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData]);

  if (__CLIENT__) updateConditionalFields();

  const updateActiveIndex = () => {
    if (typeof document !== 'undefined') {
      const kid = document.querySelector(
        '.ui.form .ui.secondary.attached.pointing.menu .active.item',
      );
      if (kid) {
        const kidId = Array.from(kid.parentElement.children).indexOf(kid);
        if (kidId !== activeIndex) setActiveIndex(kidId);
      }
    }
  };

  updateActiveIndex();

  useEffect(() => {
    const interval = setInterval(() => {
      updateActiveIndex();
    }, 100);
    return () => clearInterval(interval);
  });

  const updateRequiredFields = () => {
    if (requiredFieldsToPublish === false) {
      fetch('/++api++/@get-offer-required-fields-to-publish')
        .then((response) => response.json())
        .then((data) => {
          setRequiredFieldsToPublish(data.required_to_publish || []);
        });
    }
    if (requiredFieldsToPublish && requiredFieldsToPublish.length > 0) {
      requiredFieldsToPublish.forEach((fieldName) => {
        conditionallyMarkFieldAsRequiredToPublish(fieldName, true);
      });
    }
  };

  if (__CLIENT__ && document) updateRequiredFields();

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const switchFieldset = (forward = true) => {
    const kid = document.querySelector(
      '.ui.form .ui.secondary.attached.pointing.menu .active.item',
    );
    if (kid) {
      const kidId = Array.from(kid.parentElement.children).indexOf(kid);
      const nextId = forward ? kidId + 2 : kidId;
      const nextEl = document.querySelector(
        `.ui.form .ui.secondary.attached.pointing.menu .item:nth-child(${nextId})`,
      );
      if (nextEl) nextEl.click();
      scrollToTop();
    }
  };

  const onSave = (evt) => {
    ref.current.onSubmit(evt);
  };

  const indexOfLastFieldset = 7;

  return (
    <>
      <FormOrig ref={ref} {...props} />
      {
        /* only display buttons for contenttype `Offer` */
        isRelevantContentType &&
          /* hide on fieldset competences */
          (activeIndex !== 4 || props.type === 'Umbrella Offer') && (
            <Container>
              {activeIndex > 0 && (
                <Button
                  id="btn-prev-fieldset"
                  onClick={() => switchFieldset(false)}
                >
                  Zurück
                </Button>
              )}
              {activeIndex < indexOfLastFieldset && (
                <Button
                  color="green"
                  id="btn-next-fieldset"
                  onClick={() => switchFieldset(true)}
                >
                  Weiter
                  <Icon name="arrow right" />
                </Button>
              )}
              {activeIndex === indexOfLastFieldset && (
                <Button
                  color="blue"
                  id="btn-next-fieldset"
                  onClick={(evt) => onSave(evt)}
                >
                  <Icon name="save outline" /> Speichern
                </Button>
              )}
            </Container>
          )
      }
    </>
  );
});

export default Form;
