import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { OfferCard } from '../..';
import './style.less';
import cx from 'classnames';

const SubOffer = ({ content, onToggleSelect, selected }) => {
  const onClick = () => {
    onToggleSelect(content);
  };
  return (
    <div
      className={cx('offer-card-wrapper', { selected: selected })}
      onClick={onClick}
      onKeyDown={onClick}
      role="checkbox"
      aria-checked={selected}
      tabIndex={0}
    >
      <OfferCard
        content={content}
        compact={true}
        linkDisabled={true}
        slot={
          selected && (
            <div className="overlay">
              <Icon name="check circle" size="big" />
            </div>
          )
        }
      />
    </div>
  );
};

const SelectSubOffers = ({ id, onChange, value, formData }) => {
  const [selectedItems, setSelectedItems] = useState(value);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      searchContent('/', {
        portal_type: ['Offer'],
        fullobjects: false,
        b_size: 999,
      }),
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch]);
  const items = useSelector((state) => state.search?.items || []).filter(
    /* do not allow to select offer as own sub offer */
    (i) => flattenToAppURL(i['@id']) !== flattenToAppURL(formData['@id']),
  );

  const toggleSelectItem = (item) => {
    const newSelectedItems =
      selectedItems
        .filter((i) => i)
        .map((i) => flattenToAppURL(i['@id']))
        .indexOf(flattenToAppURL(item['@id'])) > -1
        ? selectedItems.filter(
            (i) =>
              i && flattenToAppURL(i['@id']) !== flattenToAppURL(item['@id']),
          )
        : selectedItems.filter((i) => i).concat(item);
    onChange(id, newSelectedItems);
    setSelectedItems(newSelectedItems);
  };

  return (
    <div className="select-sub-offers">
      {selectedItems.length < 2 && (
        <Segment color="red" inverted>
          Ein Dachangebot muss aus mindestens zwei Teilangeboten bestehen. Bitte
          fügen Sie Teilangebote hinzu.
        </Segment>
      )}
      <h4>Teilangebote auswählen</h4>
      <i>{selectedItems.length} ausgewählt</i>
      {items ? (
        <Grid>
          {items.map((item) => (
            <Grid.Column
              mobile={12}
              tablet={6}
              computer={6}
              largeScreen={4}
              widescreen={4}
              key={item['@id']}
              className="stretched"
            >
              <SubOffer
                content={item}
                onToggleSelect={toggleSelectItem}
                selected={
                  selectedItems
                    .filter((i) => i)
                    .map((i) => flattenToAppURL(i['@id']))
                    .indexOf(flattenToAppURL(item['@id'])) > -1
                }
              />
            </Grid.Column>
          ))}
        </Grid>
      ) : (
        <span>Lade ...</span>
      )}
    </div>
  );
};

export default SelectSubOffers;
