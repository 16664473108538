import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { getCard } from '@package/actions';
import { deleteContent } from '@plone/volto/actions';
import { Icon, Toast } from '@plone/volto/components';
import { flattenToAppURL, usePrevious } from '@plone/volto/helpers';
import DeleteSVG from '@plone/volto/icons/delete.svg';

const DeleteOfferButton = ({ content }) => {
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const path = flattenToAppURL(content['@id']);
  const requestCard = useSelector(
    (state) => state.card?.subrequests?.[path] || false,
  );
  useEffect(() => {
    if (requestCard === false) dispatch(getCard(path));
  }, [dispatch, requestCard, path]);

  const canDelete = requestCard?.data?.can_delete || false;

  const requestDelete = useSelector((state) => state.content?.delete || false);
  const prevRequestDeleteLoading = usePrevious(requestDelete?.loading || false);

  const onDoDelete = () => {
    dispatch(deleteContent(flattenToAppURL(content['@id'])));
    setModalDeleteOpen(false);
  };

  if (requestDelete.loading === false && prevRequestDeleteLoading)
    history.push(window.location.pathname.split('/').slice(0, -1).join('/'));

  if (!canDelete) return <></>;

  return requestDelete?.loading ? (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <Loader active />
    </div>
  ) : (
    <>
      {prevRequestDeleteLoading &&
        requestDelete.loaded &&
        toast.info(
          <Toast
            info
            title="Angebotsbeschreibung gelöscht"
            content="Die Angebotsbeschreibung wurde erfolgreich gelöscht."
          />,
          {
            toastId: 'deletedinfo',
            autoClose: false,
            closeButton: false,
            transition: null,
          },
        )}
      <button
        onClick={() => {
          setModalDeleteOpen(true);
        }}
        style={{ color: '#e40166' }}
      >
        {' '}
        <Icon
          name={DeleteSVG}
          className="circled"
          title="Angebotsbeschreibung löschen"
        />
      </button>

      <Modal
        closeIcon
        onClose={() => setModalDeleteOpen(false)}
        onOpen={() => setModalDeleteOpen(true)}
        open={modalDeleteOpen}
      >
        <Modal.Header>Aktion bestätigen</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Die Angebotsbeschreibung "
              <b>{content.title_long || content.title}</b>" wirklich löschen?
            </p>
            <p>
              <i>Das kann nicht rückgängig gemacht werden.</i>
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {requestDelete.loading ? (
            <Loader active />
          ) : (
            <>
              <Button color="black" onClick={() => setModalDeleteOpen(false)}>
                Nein
              </Button>
              <Button
                content="Ja, löschen"
                labelPosition="right"
                icon="trash"
                onClick={onDoDelete}
                negative
              />
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DeleteOfferButton;
