import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { getCard } from '@package/actions';
import { copyContent, updateContent } from '@plone/volto/actions';
import { Icon, Toast } from '@plone/volto/components';
import { flattenToAppURL, usePrevious } from '@plone/volto/helpers';
import CopySVG from '@plone/volto/icons/copy.svg';

const CopyOfferButton = ({ content }) => {
  const [modalDuplicateOpen, setModalDuplicateOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const path = flattenToAppURL(content['@id']);
  const requestCard = useSelector(
    (state) => state.card?.subrequests?.[path] || false,
  );
  useEffect(() => {
    if (requestCard === false) dispatch(getCard(path));
  }, [dispatch, requestCard, path]);

  const requestCopy = useSelector((state) => state.clipboard?.request || false);
  const prevRequestCopyLoading = usePrevious(requestCopy?.loading || false);

  const requestUpdate = useSelector((state) => state.content?.update || false);
  const prevRequestUpdatedLoading = usePrevious(
    requestUpdate?.loading || false,
  );

  const target = requestCopy?.data?.[0]?.target || false;
  if (prevRequestCopyLoading && requestCopy.loaded && target) {
    const newTitleLong = `Kopie von ${content.title}`;
    const newTitle =
      newTitleLong.length > 30 ? newTitleLong.slice(0, 29) + '…' : newTitleLong;

    dispatch(
      updateContent(flattenToAppURL(target), {
        title: newTitle,
        title_long: newTitleLong,
      }),
    );
  }

  if (prevRequestUpdatedLoading && requestUpdate.loaded)
    history.push(flattenToAppURL(target));

  const onDoDuplicate = () => {
    const sourcePath = flattenToAppURL(content['@id']);
    /* remove last part of path for target */
    const targetPath = sourcePath.split('/').slice(0, -1).join('/');
    dispatch(copyContent([sourcePath], targetPath));
    setModalDuplicateOpen(false);
  };

  const canCopy = requestCard?.data?.can_copy || false;

  /* hide unlock-toast for all users, since we are not able to find out if user has edit permission,
     because editAction is always false when content is locked
  */
  if (__CLIENT__ && content.lock?.locked === true) {
    // keep unlock-button, otherwise users would not be able to steal lock at all
    // let el = document.querySelector('#toolbar .toolbar-actions button.unlock');
    // if (el) el.style.display = 'none';
    let el = document.querySelector('.Toastify');
    if (el) el.style.display = 'none';
  }

  if (!canCopy) return <></>;

  return requestCopy?.loading || requestUpdate?.loading ? (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <Loader active />
    </div>
  ) : (
    <>
      {prevRequestUpdatedLoading &&
        requestUpdate.loaded &&
        toast.info(
          <Toast
            info
            title="Duplikat erstellt"
            content="Die Angebotsbeschreibung wurde erfolgreich dupliziert."
          />,
          {
            toastId: 'duplicatedinfo',
            autoClose: false,
            closeButton: false,
            transition: null,
          },
        )}
      <button
        onClick={() => {
          setModalDuplicateOpen(true);
        }}
        style={{ color: '#007eb1' }}
      >
        {' '}
        <Icon
          name={CopySVG}
          className="circled"
          title="Angebotsbeschreibung duplizieren"
        />
      </button>

      <Modal
        closeIcon
        onClose={() => setModalDuplicateOpen(false)}
        onOpen={() => setModalDuplicateOpen(true)}
        open={modalDuplicateOpen}
      >
        <Modal.Header>Aktion bestätigen</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {content.title.startsWith('Kopie von') && (
              <p>
                <b>
                  Diese Angebotsbeschreibung scheint bereits die Kopie einer
                  anderen Angebotsbeschreibung zu sein!
                </b>
              </p>
            )}
            <p>Diese Angebotsbeschreibung wirklich duplizieren?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalDuplicateOpen(false)}>
            Nein
          </Button>
          <Button
            content="Ja, duplizieren"
            labelPosition="right"
            icon="checkmark"
            onClick={onDoDuplicate}
            positive
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CopyOfferButton;
