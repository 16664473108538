import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {
  Checkbox,
  Container,
  Grid,
  Icon,
  Input,
  Label,
  Loader,
} from 'semantic-ui-react';
import { getContent, searchContent, getVocabulary } from '@plone/volto/actions';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import FullCalendarListing from '@mbarde/volto-fullcalendar-block-original/components/manage/Blocks/Listing/FullCalendar';
import { DropdownWithCheckboxes, NavPills } from '@package/components';
import { OfferCard } from '..';
import './search.less';

const messages = defineMessages({
  online: {
    id: 'Online',
    defaultMessage: 'Online',
  },
  presence: {
    id: 'In presence',
    defaultMessage: 'In presence',
  },
  hybrid: {
    id: 'Hybrid',
    defaultMessage: 'Hybrid',
  },
  other: {
    id: 'Other',
    defaultMessage: 'Other',
  },
  winterSemester: {
    id: 'Winter semester',
    defaultMessage: 'Winter semester',
  },
  summerSemester: {
    id: 'Summer semester',
    defaultMessage: 'Summer semester',
  },
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
  countSelected: {
    id: '{count} selected',
    defaultMessage: '{count} selected',
  },
  semester: {
    id: 'Semester',
    defaultMessage: 'Semester',
  },
  format: {
    id: 'Implementation format',
    defaultMessage: 'Implementation format',
  },
  kind: {
    id: 'Offer kind',
    defaultMessage: 'Offer kind',
  },
  educational_sciences: {
    id: 'Educational Sciences',
    defaultMessage: 'Educational Sciences',
  },
  arts_and_humanities: {
    id: 'Arts / Humanities',
    defaultMessage: 'Arts / Humanities',
  },
  mathematics_natural_sciences: {
    id: 'Mathematics / Natural Sciences',
    defaultMessage: 'Mathematics / Natural Sciences',
  },
  computer_science: {
    id: 'Computer Science',
    defaultMessage: 'Computer Science',
  },
});

const OFFER_KINDS = (intl) => [
  { token: 'umbrella', title: 'Dachangebot' },
  { token: 'learning_pathway', title: 'Lernpfad-Angebot' },
  { token: 'block', title: 'Blockveranstaltung' },
  { token: 'self', title: 'Selbstlernkurs' },
  { token: 'seminar', title: 'Seminar' },
  { token: 'exercise', title: 'Übung' },
  { token: 'lecture', title: 'Vortrag / Vorlesung' },
  { token: 'workshop', title: 'Workshop' },
  { token: 'other', title: 'Sonstiges' },
];

const PROGRAM_FORMATS = (intl) => [
  { token: 'presence', title: intl.formatMessage(messages.presence) },
  { token: 'online', title: intl.formatMessage(messages.online) },
  { token: 'hybrid', title: intl.formatMessage(messages.hybrid) },
  { token: 'other', title: 'verschiedene Formate' },
];

const SEMESTERS = (intl) => [
  { token: 'wise', title: intl.formatMessage(messages.winterSemester) },
  { token: 'sose', title: intl.formatMessage(messages.summerSemester) },
  { token: 'free', title: 'Zeitlich unabhängig' },
];

/* Randomize array in-place using Durstenfeld shuffle algorithm
   (https://stackoverflow.com/a/12646864)
*/
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const OffersSearchView = ({ content }) => {
  const [showOnlyMyOffers, setShowOnlyMyOffers] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const userId = token ? jwtDecode(token).sub : '';

  const intersect = (a, b) => {
    const setB = new Set(b);
    return [...new Set(a)].filter((x) => setB.has(x));
  };

  const location = useLocation();

  /* parse URL parameters (after `?`) */
  const params = Object.fromEntries(
    location.search
      .slice(1)
      .split('&')
      .filter((p) => p.indexOf('=') > -1)
      .map((p) => {
        const [key, value] = p.split('=');
        return [key, value.split(',')];
      }),
  );

  const [filterCompetences, setFilterCompetences] = useState(
    params['competences'] || [],
  );
  const [filterInstitutions, setFilterInstitutions] = useState(
    params['institution'] || [],
  );
  const [filterFormat, setFilterFormat] = useState(params['format'] || []);
  const [filterKind, setFilterKind] = useState(params['kind'] || []);
  const [filterSemester, setFilterSemester] = useState(
    params['semester'] || [],
  );
  const [searchInput, setSearchInput] = useState(
    params['q']?.length > 0 ? decodeURIComponent(params['q'][0]) : '',
  );

  const resetAllFilters = () => {
    setFilterCompetences([]);
    setFilterInstitutions([]);
    setFilterFormat([]);
    setFilterSemester([]);
    setFilterKind([]);
    setSearchInput('');
    if (typeof window !== 'undefined' && window.innerWidth < 992)
      setFiltersCollapsed(true);
  };

  const areFiltersResetable =
    filterCompetences.length > 0 ||
    filterInstitutions.length > 0 ||
    filterFormat.length > 0 ||
    filterSemester.length > 0 ||
    searchInput.length > 0 ||
    filterKind.length > 0;

  const [filtersCollapsed, setFiltersCollapsed] = useState(false);
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.innerWidth < 992 &&
      !areFiltersResetable
    )
      setFiltersCollapsed(true);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const dispatch = useDispatch();
  const intl = useIntl();

  const compareItems = (a, b) => {
    const cmpA =
      (a.period_from || '3000-12-31') + '-' + a.title ||
      a.title_long + '-' + a.degree?.map((d) => d.token).join('-');
    const cmpB =
      (b.period_from || '3000-12-31') + '-' + b.title ||
      b.title_long + '-' + b.degree?.map((d) => d.token).join('-');
    return cmpA < cmpB ? -1 : cmpA > cmpB ? 1 : 0;
  };

  const itemsBeforeFilter = useSelector((state) => state.search?.items);

  /* display 5 random keywords as suggestions */
  let keywords = [];
  itemsBeforeFilter.forEach((i) => {
    (i.offer_keywords || []).forEach((keyword) => {
      if (keywords.indexOf(keyword) === -1) keywords.push(keyword);
    });
  });
  shuffleArray(keywords);
  keywords = keywords.slice(0, 5);

  const isArchive = location.pathname.indexOf('/archiv') > -1;

  const items =
    itemsBeforeFilter
      .filter((i) => i)
      /* filter expired offers */
      .filter((i) =>
        isArchive
          ? new Date() >= new Date(i.period_to || '3000-12-31')
          : new Date() < new Date(i.period_to || '3000-12-31'),
      )
      /* filter institutions */
      .filter(
        (i) =>
          filterInstitutions.length === 0 ||
          filterInstitutions.indexOf(i.institution) > -1,
      )
      /* filter semester */
      .filter(
        (i) =>
          filterSemester.length === 0 ||
          filterSemester.indexOf(i.semester || '') > -1,
      )
      /* filter competences */
      .filter(
        (i) =>
          filterCompetences.length === 0 ||
          intersect(Object.keys(i.competences || {}), filterCompetences)
            .length > 0,
      )
      /* filter formats */
      .filter(
        (i) =>
          filterFormat.length === 0 ||
          filterFormat.indexOf(i.offer_format) > -1,
      )
      /* filter kinds */
      .filter(
        (i) =>
          filterKind.length === 0 ||
          filterKind.indexOf(i.offer_kind) > -1 ||
          (filterKind.indexOf('other') > -1 &&
            i.offer_kind_other?.length > 0) ||
          (filterKind.indexOf('learning_pathway') > -1 &&
            i.is_learning_pathway),
      )
      /* filter by text */
      .filter(
        (i) =>
          searchInput.length === 0 ||
          (i.title_long &&
            i.title_long.toLowerCase().indexOf(searchInput.toLowerCase()) >
              -1) ||
          (i.title &&
            i.title.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) ||
          /* or hashtag aka. subject */
          (i.offer_keywords || [])
            .map((s) => '#' + s.toLowerCase())
            .join(' ')
            .indexOf(searchInput.toLowerCase()) > -1 ||
          /* teacher */
          (i.teacher?.title?.toLowerCase() || '').indexOf(
            searchInput.toLowerCase(),
          ) > -1 ||
          /* authors */
          i.authors.filter(
            (a) =>
              (a.title?.toLowerCase() || '').indexOf(
                searchInput.toLowerCase(),
              ) > -1,
          ).length > 0,
      )
      /* filter showOnlyMyOffers */
      .filter(
        (i) =>
          !showOnlyMyOffers ||
          [i.creator]
            .concat(i.teacher?.email ? [i.teacher.email] : [])
            .concat(
              i.authors
                ? i.authors.filter((a) => a.email).map((a) => a.email)
                : [],
            )
            .concat(
              i.editors
                ? i.editors.filter((e) => e.email).map((e) => e.email)
                : [],
            )
            .indexOf(userId) > -1,
      )
      .sort(compareItems) || [];

  const hasLoaded = useSelector((state) => state.search?.loaded || false);
  const isOfferAddable = useSelector((state) =>
    Array.isArray(state.types.types)
      ? state.types.types.find((t) => t.addable === true && t.id === 'Offer')
      : false,
  );

  let pathQuery = [];
  try {
    /* we abuse the field `preview_caption` to store path query
       (should be an array of strings)
    */
    pathQuery = JSON.parse(content.preview_caption);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  useEffect(() => {
    dispatch(
      searchContent('/', {
        portal_type: ['Offer', 'Umbrella Offer'],
        fullobjects: false,
        b_size: 999,
        'path.query': pathQuery,
        'path.depth': 1,
      }),
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch]);

  /* retrieve DigCompEdu schema to get list of competences */
  const url = '/de/digcompedu-schema';
  const subrequest = useSelector((state) => state.content.subrequests?.[url]);
  const digCompEduSchema = subrequest?.data?.schema?.items || false;
  useEffect(() => {
    if (
      url !== false &&
      subrequest?.loading !== true &&
      subrequest?.loaded !== true
    )
      dispatch(getContent(url, null, url));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  const vocabEcampusOrgUnits = useSelector(
    (state) => state.vocabularies?.['ecampus.orgunits'] || false,
  );
  useEffect(() => {
    if (
      vocabEcampusOrgUnits?.loading !== true &&
      vocabEcampusOrgUnits?.loaded !== true
    )
      dispatch(
        getVocabulary({ vocabNameOrURL: 'ecampus.orgunits', size: 99999 }),
      );
  }, [dispatch, vocabEcampusOrgUnits]);
  const presentInstitutions = itemsBeforeFilter
    .filter((i) => i.institution)
    .map((i) => i.institution);
  const eCampusOrgUnits = vocabEcampusOrgUnits?.items || [];
  const institutions = eCampusOrgUnits
    /* only display available orgunits */
    .filter((o) => presentInstitutions.indexOf(o.value) > -1)
    .map((o) => {
      return { token: o.value, title: o.label };
    });

  /* only display available compentences */
  const presentCompetences = itemsBeforeFilter
    .filter((i) => i.competences)
    .map((i) => Object.keys(i.competences))
    .flat();
  let competences = [];
  if (digCompEduSchema) {
    Object.entries(digCompEduSchema)
      .map((e) =>
        e[1].children.map((c) => {
          return { token: c.id, title: c.id + ' ' + c.label };
        }),
      )
      .flat()
      .forEach((e) => {
        if (presentCompetences.indexOf(e.token) > -1) competences.push(e);
      });
    competences.sort((a, b) => a.title > b.title);
  }

  const formats = PROGRAM_FORMATS(intl);
  const kinds = OFFER_KINDS(intl);
  const semesters = SEMESTERS(intl);

  const onChangeFilterFaculties = (evt, data) => {
    if (data.checked === true && filterInstitutions.indexOf(data.value) === -1)
      setFilterInstitutions(filterInstitutions.concat([data.value]));
    if (data.checked === false && filterInstitutions.indexOf(data.value) > -1)
      setFilterInstitutions(filterInstitutions.filter((d) => d !== data.value));
  };

  const onChangeFilterCompetences = (evt, data) => {
    if (data.checked === true && filterCompetences.indexOf(data.value) === -1)
      setFilterCompetences(filterCompetences.concat([data.value]));
    if (data.checked === false && filterCompetences.indexOf(data.value) > -1)
      setFilterCompetences(filterCompetences.filter((d) => d !== data.value));
  };

  const onChangeFilterFormat = (evt, data) => {
    if (data.checked === true && filterFormat.indexOf(data.value) === -1)
      setFilterFormat(filterFormat.concat([data.value]));
    if (data.checked === false && filterFormat.indexOf(data.value) > -1)
      setFilterFormat(filterFormat.filter((d) => d !== data.value));
  };

  const onChangeFilterKind = (evt, data) => {
    if (data.checked === true && filterKind.indexOf(data.value) === -1)
      setFilterKind(filterKind.concat([data.value]));
    if (data.checked === false && filterKind.indexOf(data.value) > -1)
      setFilterKind(filterKind.filter((d) => d !== data.value));
  };

  const onChangeFilterSemester = (evt, data) => {
    if (data.checked === true && filterSemester.indexOf(data.value) === -1)
      setFilterSemester(filterSemester.concat([data.value]));
    if (data.checked === false && filterSemester.indexOf(data.value) > -1)
      setFilterSemester(filterSemester.filter((d) => d !== data.value));
  };

  /* update URL params accordingly to filters */
  if (typeof window !== 'undefined') {
    let params = {};
    if (filterCompetences.length > 0)
      params['competences'] = filterCompetences.join(',');
    if (filterInstitutions.length > 0)
      params['institutions'] = filterInstitutions.join(',');
    if (filterFormat.length > 0) params['format'] = filterFormat.join(',');
    if (filterKind.length > 0) params['kind'] = filterKind.join(',');
    if (filterSemester.length > 0)
      params['semester'] = filterSemester.join(',');
    if (searchInput.length > 0) params['q'] = encodeURIComponent(searchInput);

    const paramsStr = Object.entries(params)
      .map((e) => `${e[0]}=${e[1]}`)
      .join('&');

    const nextURL =
      paramsStr.length > 0
        ? `${location.pathname}?${paramsStr}`
        : location.pathname;
    const nextTitle = content.title;
    const nextState = { additionalInformation: 'Updated the URL with JS' };
    window.history.replaceState(nextState, nextTitle, nextURL);
  }

  const dates = items
    .filter((item) => item.offer_dates)
    .map((item) =>
      item.offer_dates.items
        .filter((od) => od.start && od.end)
        .map((od) => {
          return {
            '@id': item['@id'],
            title: item.title,
            description: item.description,
            start: od.start,
            end: od.end,
          };
        }),
    )
    .flat()
    .sort((e1, e2) => e2.start < e1.start);

  return (
    <Container id="program-search-container">
      {content.relatedItems?.length > 0 && (
        <NavPills
          items={
            content.relatedItems?.length > 0
              ? content.relatedItems.map((i) => {
                  return { url: flattenToAppURL(i['@id']), title: i.title };
                })
              : []
          }
          position="right"
        />
      )}
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="description">{content.description}</p>
      )}
      <Input
        className="in-search"
        placeholder={'Angebotsbeschreibung finden'}
        fluid
        icon="search"
        iconPosition="left"
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
      />
      {userId && (
        <div
          className="in-search my-offers-wrapper"
          title="Zeige nur Angebote, die Sie bearbeiten dürfen."
        >
          <Checkbox
            label="Nur meine Angebote zeigen"
            onChange={(e, data) => setShowOnlyMyOffers(data.checked)}
            checked={showOnlyMyOffers}
          />
        </div>
      )}
      {keywords?.length > 0 && (
        <Label.Group tag className="in-search">
          {keywords.map((keyword) => {
            return (
              <Label
                onClick={() => {
                  resetAllFilters();
                  setSearchInput(`#${keyword}`);
                }}
              >
                #{keyword}
              </Label>
            );
          })}
        </Label.Group>
      )}
      <div
        className={
          filtersCollapsed ? 'filters-wrapper collapsed' : 'filters-wrapper'
        }
      >
        <div className="filters">
          <DropdownWithCheckboxes
            title={'Kompetenzen'}
            items={competences}
            onChange={onChangeFilterCompetences}
            filterStatus={filterCompetences}
            initialOpen={filterCompetences.length > 0}
          />
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.semester)}
            items={semesters}
            onChange={onChangeFilterSemester}
            filterStatus={filterSemester}
            initialOpen={filterSemester.length > 0}
          />
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.format)}
            items={formats}
            onChange={onChangeFilterFormat}
            filterStatus={filterFormat}
            initialOpen={filterFormat.length > 0}
          />
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.kind)}
            items={kinds}
            onChange={onChangeFilterKind}
            filterStatus={filterKind}
            initialOpen={filterKind.length > 0}
          />
          <DropdownWithCheckboxes
            title={'Einrichtungen'}
            items={institutions}
            onChange={onChangeFilterFaculties}
            filterStatus={filterInstitutions}
            initialOpen={filterInstitutions.length > 0}
            menuDirection="left"
          />
        </div>
        {areFiltersResetable && (
          <span
            onClick={() => resetAllFilters()}
            size="tiny"
            className="reset-filters"
            onKeyDown={() => resetAllFilters()}
            role="button"
            tabIndex={0}
          >
            <FormattedMessage
              id="Reset all filters"
              defaultMessage="Reset all filters"
            />
          </span>
        )}
      </div>
      {!isArchive ? (
        <div className="archive-link">
          <UniversalLink className="cta-link" href="/de/archiv">
            <span>Zum Archiv</span>
          </UniversalLink>
        </div>
      ) : (
        <div className="archive-link">
          <UniversalLink className="cta-link" href="/de/angebot">
            <span>Zur Angebotsübersicht</span>
          </UniversalLink>
        </div>
      )}
      <Checkbox
        className="show-calendar"
        label="Kalenderansicht"
        onChange={(e, data) => setShowCalendar(data.checked)}
        checked={showCalendar}
      />
      <div className="results">
        <div className="tools">
          {hasLoaded && (
            <h3>
              {isArchive
                ? 'Archivierte Angebotsbeschreibungen'
                : 'Angebotsbeschreibungen'}{' '}
              <strong>({items.length})</strong>
            </h3>
          )}
          {filtersCollapsed && (
            <span
              className="toggle-filters"
              onClick={() => setFiltersCollapsed(false)}
              onKeyDown={() => setFiltersCollapsed(false)}
              role="button"
              tabIndex={0}
            >
              <Icon name="filter" />
              Filter
            </span>
          )}
        </div>
        {!showCalendar && (
          <Grid>
            {isOfferAddable && !isArchive && (
              <Grid.Column
                mobile={12}
                tablet={6}
                computer={6}
                largeScreen={4}
                widescreen={4}
                key="add-offer"
                className="stretched"
              >
                <a
                  className="placeholder"
                  href={location.pathname + '/add_offer'}
                >
                  <div className="meta"></div>
                  <div className="title awh">
                    <Icon name="plus" /> Hinzufügen
                  </div>
                </a>
              </Grid.Column>
            )}
            {hasLoaded
              ? items
                  .filter((i) => i.title || i.title_long)
                  .map((item) => (
                    <Grid.Column
                      mobile={12}
                      tablet={6}
                      computer={6}
                      largeScreen={4}
                      widescreen={4}
                      key={item['@id']}
                      className="stretched"
                    >
                      <OfferCard content={item} />
                    </Grid.Column>
                  ))
              : !hasLoaded && (
                  <Loader size="large" inline="centered" active>
                    Lade Angebotsbeschreibungen ...
                  </Loader>
                )}
          </Grid>
        )}
        {hasLoaded && items && showCalendar && (
          <FullCalendarListing
            items={dates}
            initial_view="dayGridMonth"
            toolbar_center={['title']}
            toolbar_left={['dayGridWeek', 'dayGridMonth', 'listMonth']}
            toolbar_right={['prev', 'today', 'next']}
            title_format_month="long"
            title_format_year="numeric"
          />
        )}
      </div>
    </Container>
  );
};

export default OffersSearchView;
