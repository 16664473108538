import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Label } from 'semantic-ui-react';

const Tags = () => {
  const content = useSelector((state) => state.content?.data || false);
  const tags = content?.offer_keywords || [];

  return (
    <Container>
      {tags?.length > 0 && (
        <Label.Group tag>
          {tags.map((tag) => {
            let tagHref = `/de/angebot/?q=%23${tag}`;
            return (
              <Link className="ui label" to={tagHref} key={tag}>
                #{tag}
              </Link>
            );
          })}
        </Label.Group>
      )}
    </Container>
  );
};

export default Tags;
