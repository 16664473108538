import { defineMessages } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import ObjectWidget from '@plone/volto/components/manage/Widgets/ObjectWidget';

const messages = defineMessages({
  labelPerson: {
    id: 'Person',
    defaultMessage: 'Person',
  },
  labelName: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  labelPhone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  labelEmail: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  labelUrl: {
    id: 'Homepage',
    defaultMessage: 'Homepage',
  },
});

const PersonSchema = (intl, full) => {
  return {
    title: intl.formatMessage(messages.labelPerson),
    properties: {
      is_external: {
        title: 'Externe Person?',
        type: 'boolean',
      },
      name: {
        title: intl.formatMessage(messages.labelName),
      },
      phone: {
        title: intl.formatMessage(messages.labelPhone),
      },
      email: {
        title: intl.formatMessage(messages.labelEmail),
      },
      url: {
        title: intl.formatMessage(messages.labelUrl),
      },
      orgunit: {
        title: 'Organisation',
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelPerson),
        fields: full
          ? ['is_external', 'name', 'phone', 'email', 'orgunit', 'url']
          : ['is_external'],
      },
    ],
    required: [],
  };
};

const PersonWidget = (props) => {
  const schema = PersonSchema(
    props.intl,
    props.formData.teacher_external?.is_external ? true : false,
  );
  if (typeof document !== 'undefined') {
    const el = document.querySelector('.field-wrapper-teacher p.help');
    if (el)
      el.style.visibility = props.formData.teacher_external?.is_external
        ? 'hidden'
        : 'visible';
  }
  return (
    <div
      style={
        props.formData.teacher_external?.is_external
          ? {
              marginTop: '-114px',
            }
          : {}
      }
    >
      <Grid style={{ marginLeft: '0', marginRight: '0' }}>
        <Grid.Row stretched>
          <Grid.Column width={4}></Grid.Column>
          <Grid.Column
            width={8}
            style={{ background: 'white', paddingLeft: '0' }}
          >
            <ObjectWidget schema={schema} {...props} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default PersonWidget;
