import { useState } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { PersonCard } from '@package/components';

const WeipoPersonCard = ({ uid }) => {
  const [error, setError] = useState(false);
  const [person, setPerson] = useState(false);

  if (__CLIENT__ && !person && !error) {
    /* In the future uid should actually contain the real object UID,
       instead of a URL to make it robust against content movements.
       But currently we cannot resolve UID to path because of CORS,
       so this is a temporary ugly fix.
    */
    const url = uid.replace(
      'https://www.uni-koblenz.de/',
      'https://www.uni-koblenz.de/++api++/',
    );
    fetch(url)
      .then((res) => res.json())
      .then((data) => setPerson(data))
      .catch((error) => {
        setError(error);
      });
  }

  if (error)
    return (
      <Message warning>
        <Icon name="warning sign" /> Visitenkarte konnte nicht geladen werden:
        <br />
        <a href={uid} target="_blank" rel="noreferrer">
          {uid}
        </a>
      </Message>
    );

  return person ? (
    <PersonCard person={person} no-hydration={true} />
  ) : (
    <span>Lade ...</span>
  );
};

export default WeipoPersonCard;
