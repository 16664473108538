import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Container,
  Dimmer,
  Input,
  Loader,
  Form,
  FormField,
  Message,
  Radio,
  Segment,
} from 'semantic-ui-react';
import { createContent, getContent } from '@plone/volto/actions';
import { RenderBlocks } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { TextWithGlossaryTooltips } from '@rohberg/volto-slate-glossary/utils';
import './add.less';

const AddForm = ({ location }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [title, setTitle] = useState('');
  const [isUmbrella, setIsUmbrella] = useState(null);

  /* load info text for offer type */
  const url = '/de/hilfe/angebotsbeschreibung-erstellen';
  const contentSubrequest = useSelector(
    (state) => state.content?.subrequests?.[url] || false,
  );
  const offerTypeHelpPage = contentSubrequest?.data;
  useEffect(() => {
    if (url !== false && !offerTypeHelpPage) {
      dispatch(getContent(url, null, url, null, true));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  const content = useSelector((state) => state.content?.data || false);
  if (content)
    return <Redirect to={flattenToAppURL(content['@id']) + '/edit'} />;

  const submitForm = () => {
    if (isUmbrella === null) {
      setError({
        field: 'umbrella',
        message: 'Bitte treffen Sie eine Auswahl.',
      });
      return;
    }
    if (title.length === 0) {
      setError({
        field: 'title',
        message: 'Eingabe benötigt.',
      });
      return;
    } else if (title.length > 30) {
      setError({
        field: 'title',
        message: 'Bitte beschränken Sie die Länge auf 30 Zeichen.',
      });
      return;
    }
    const container = location.pathname.split('/').slice(0, -1).join('/');
    dispatch(
      createContent(container, {
        '@type': isUmbrella ? 'Umbrella Offer' : 'Offer',
        title: title,
      }),
    );
  };

  return (
    <Container id="add-offer-form">
      <h1>
        <TextWithGlossaryTooltips text={'Angebotsbeschreibung erstellen'} />
      </h1>
      <Form>
        {contentSubrequest.loading && (
          <Segment>
            <Dimmer active>
              <Loader content="Lade ..." />
            </Dimmer>
          </Segment>
        )}
        {offerTypeHelpPage && (
          <div className="help-page">
            <RenderBlocks path={url} content={offerTypeHelpPage} />
          </div>
        )}
        {error && error.field === 'umbrella' && (
          <Message negative>{error.message}</Message>
        )}
        <br />
        <FormField>
          <Radio
            label={
              <label>
                <TextWithGlossaryTooltips
                  text={'Ich möchte ein Angebot erstellen.'}
                />
              </label>
            }
            name="offertype"
            value="default"
            checked={isUmbrella === false}
            onChange={() => setIsUmbrella(false)}
          />
        </FormField>
        <FormField>
          <Radio
            label={
              <label>
                <TextWithGlossaryTooltips
                  text={
                    'Ich möchte ein Dachangebot (oder auch ein Lernpfadangebot) erstellen.'
                  }
                />
              </label>
            }
            name="offertype"
            value="umbrella"
            checked={isUmbrella === true}
            onChange={() => setIsUmbrella(true)}
          />
        </FormField>
        <h4>
          <TextWithGlossaryTooltips
            text={'Kurztitel des Weiterbildungsangebots'}
          />
          :
        </h4>
        <div className="description">
          <TextWithGlossaryTooltips
            text={
              'Der Kurztitel darf maximal 30 Zeichen haben. Im weiteren Verlauf können Sie den vollständigen Titel egänzen.'
            }
          />
          <br />
          <TextWithGlossaryTooltips
            text={
              'Die URL dieser Angebotsbeschreibung wird sich von dem Kurztitel ableiten.'
            }
          />
        </div>
        <FormField
          id="title"
          control={Input}
          value={title}
          onChange={(evt) => {
            setTitle(evt.target.value);
          }}
          error={
            error && error.field === 'title'
              ? {
                  content: error.message,
                }
              : false
          }
        />
        <div className="char-count">
          <i>{title.length} / 30 Zeichen</i>
        </div>
        <Button type="submit" onClick={submitForm} primary>
          Erstellen
        </Button>
      </Form>
    </Container>
  );
};

export default AddForm;
