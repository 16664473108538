import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import {
  Dimmer,
  DimmerDimmable,
  Icon,
  Placeholder,
  PlaceholderHeader,
  PlaceholderLine,
} from 'semantic-ui-react';
import { getContent } from '@plone/volto/actions';
import { RenderBlocks, Sidebar, SidebarPortal } from '@plone/volto/components';
import { Segment } from 'semantic-ui-react';

const SidebarHelp = ({ content, location }) => {
  const [isClient, setIsClient] = useState(false);
  const [selectedFieldset, setSelectedFieldset] = useState(false);
  const [title, setTitle] = useState(false);

  const dontDisplay =
    !content ||
    !location ||
    !(
      (['Offer', 'Umbrella Offer'].indexOf(content['@type']) > -1 &&
        location.pathname.endsWith('/edit')) ||
      (location.pathname.endsWith('/add') &&
        (location.search === '?type=Offer' ||
          location.search === '?type=Umbrella Offer'))
    );

  useEffect(() => {
    setIsClient(true);
  }, []);

  let url = false;
  const updateKid = () => {
    if (dontDisplay) return;
    const kid =
      isClient &&
      document.querySelector(
        '.ui.secondary.attached.pointing.menu .active.item',
      );
    if (kid) {
      if (kid.innerText !== title) setTitle(kid.innerText);
      const kidId = Array.from(kid.parentElement.children).indexOf(kid);
      if (kidId !== selectedFieldset) setSelectedFieldset(kidId);
      const folder =
        content['@type'] === 'Umbrella Offer' ? 'dachangebot' : 'angebot';
      url = `/de/hilfe/${folder}/fieldset-${kidId}`;
    }
  };
  updateKid();

  useEffect(() => {
    const interval = setInterval(() => {
      updateKid();
    }, 100);
    return () => clearInterval(interval);
  });

  const contentSubrequests = useSelector((state) => state.content.subrequests);
  const dispatch = useDispatch();
  const fullPage = contentSubrequests?.[url]?.data;
  useEffect(() => {
    if (url !== false && !fullPage) {
      dispatch(getContent(url, null, url, null, true));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  if (dontDisplay) {
    if (selectedFieldset !== false) setSelectedFieldset(false);
    return <></>;
  }

  return (
    <>
      <Portal node={__CLIENT__ && document.getElementById('sidebar')}>
        <Sidebar blockTab={false} settingsTab={false} />
      </Portal>
      <SidebarPortal selected={true} tab={'sidebar-metadata'}>
        <Segment secondary>
          <b>Hilfebereich</b>
        </Segment>
        <Segment>
          {fullPage ? (
            <RenderBlocks path={url} content={fullPage} />
          ) : contentSubrequests?.[url]?.loading ? (
            <DimmerDimmable as={Segment} dimmed={true}>
              {title && <h1 className="documentFirstHeading">{title}</h1>}
              <PlaceholderHeader image>
                <PlaceholderLine />
                <PlaceholderLine />
              </PlaceholderHeader>
              <Placeholder>
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
              </Placeholder>
              <Dimmer active={true} />
            </DimmerDimmable>
          ) : (
            <span>
              <Icon name="ban" /> Kein Inhalt.
            </span>
          )}
        </Segment>
      </SidebarPortal>
    </>
  );
};

export default SidebarHelp;
