import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ConditionalLink, Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import Item from './Item';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

import ImageGallery from 'react-image-gallery';

/*
  extend ImageGallery component to allow custom renderItem function
  (using our custom Item component).
  Without extending the original component we would not be able to
  refer to this.handleImageLoaded and this.handleImageError.
*/
class MyImageGallery extends ImageGallery {
  constructor(props) {
    super(props);

    // necessary bindings
    this.renderItem = this.renderItem.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  /* avoid a re-render everytime `curIndex` in parent `HeroGalleryTemplate`
    changes: only re-render when state changes
    (for example switch to next slide)
  */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    } else {
      return false;
    }
  }

  renderItem(item) {
    const { isFullscreen } = this.state;
    const { onImageError } = this.props;
    const handleImageError = onImageError || this.handleImageError;

    return (
      <Item
        title={item.originalTitle}
        description={item.description}
        fullscreen={item.fullscreen}
        handleImageLoaded={this.handleImageLoaded}
        isFullscreen={isFullscreen}
        onImageError={handleImageError}
        original={item.original}
        originalAlt={item.originalAlt}
        originalHeight={item.originalHeight}
        originalWidth={item.originalWidth}
        originalTitle={item.originalTitle}
        sizes={item.sizes}
        srcSet={item.srcSet}
        link={item.link}
        isEditMode={this.props.isEditMode}
        bulletOnClick={this.props.bulletOnClick}
        showOverlays={this.props.showOverlays}
      />
    );
  }
}

/*
  Customized version of @plone/volto/components/manage/Blocks/Listing/ImageGallery
*/
const HeroGalleryTemplate = ({
  items,
  isEditMode,
  block,
  widthCapped = true,
  slideInterval = 10000,
  showOverlays = true,
  showBullets = true,
}) => {
  const { settings } = config;
  const galleryRef = useRef(null);

  const [curIndex, setCurIndex] = useState(0);

  const bulletOnClick = () => {
    galleryRef.current.pause();
  };

  const renderItems = items.filter(
    (content) =>
      settings.imageObjects.includes(content['@type']) ||
      /* also show News Items which have a lead image */
      (content['@type'] === 'News Item' && content.image_field),
  );

  const imagesInfo = renderItems.map((item) => {
    let link =
      item['@type'] === 'News Item'
        ? item['@id']
        : item.relatedItems?.length > 0 && item.relatedItems[0]
        ? item.relatedItems[0]['@id']
        : '';
    const baseUrl = `${flattenToAppURL(item['@id'])}/@@images/${
      item.image_field
    }`;

    const srcSet = [
      `${baseUrl}/preview 400w`,
      `${baseUrl}/teaser 600w`,
      `${baseUrl}/large 800w`,
      `${baseUrl}/larger 1000w`,
      `${baseUrl}/great 13111w`,
      `${baseUrl}/huge 1920w`,
    ];

    return {
      original: `${baseUrl}/thumb`,
      originalTitle: item.title,
      description: item.description,
      thumbnail: `${baseUrl}/thumb`,
      link: link,
      srcSet: srcSet.join(', '),
      bulletOnClick: bulletOnClick,
    };
  });

  const onSlide = (index) => {
    setCurIndex(index);
  };

  return (
    renderItems.length > 0 && (
      <div
        className={
          isEditMode ? '' : widthCapped ? 'fullwidth capped' : 'fullwidth'
        }
      >
        <MyImageGallery
          ref={galleryRef}
          items={imagesInfo}
          showNav={false}
          showFullscreenButton={false}
          showPlayButton={false}
          lazyLoad={true}
          showThumbnails={false}
          showBullets={showBullets && imagesInfo.length > 1}
          autoPlay={true}
          slideInterval={slideInterval}
          isEditMode={isEditMode}
          bulletOnClick={bulletOnClick}
          onSlide={onSlide}
          showOverlays={showOverlays}
        />
        {showOverlays && imagesInfo && imagesInfo.length > curIndex && (
          <ConditionalLink
            to={imagesInfo[curIndex].link}
            condition={!isEditMode && imagesInfo[curIndex].link.length > 0}
          >
            <div className="description-mobile awh">
              <div className="image-gallery-description-arrow">
                <span>
                  <Icon name={arrowSVG} className="link" color="white" />
                </span>
              </div>
              <div>
                {imagesInfo[curIndex].originalTitle}
                {imagesInfo[curIndex].description && (
                  <span className="subhead">
                    {imagesInfo[curIndex].description}
                  </span>
                )}
              </div>
            </div>
          </ConditionalLink>
        )}
      </div>
    )
  );
};

HeroGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default HeroGalleryTemplate;
