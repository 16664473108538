import { Popup } from 'semantic-ui-react';
import {
  AddOfferForm,
  DigCompEduWidget,
  ListOfDatesWidget,
  ListOfTextWidget,
  OfferView,
  OffersSearchView,
  PersonWidget,
  SelectSubOffers,
  SidebarHelp,
  UmbrellaOfferView,
} from './components';
import { ConditionalLink } from '@plone/volto/components';
import '@plone/volto/config';
import { flattenToAppURL } from '@plone/volto/helpers';
import TokenWidget from '@plone/volto/components/manage/Widgets/TokenWidget';
import { Tooltips } from '@rohberg/volto-slate-glossary/components';
import cardSVG from './svgs/icons/card.svg';
import cardRoofSVG from './svgs/icons/card_roof_11.svg';
import './style.less';

const applyConfig = (config) => {
  config.views.contentTypesViews.Offer = OfferView;
  config.views.contentTypesViews['Umbrella Offer'] = UmbrellaOfferView;
  config.widgets.widget.digcompedu_widget = DigCompEduWidget;
  config.widgets.widget.list_of_text_widget = ListOfTextWidget;
  config.widgets.widget.list_of_dates_widget = ListOfDatesWidget;
  config.widgets.widget.person_widget = PersonWidget;
  config.widgets.id.sub_offers = SelectSubOffers;
  config.views.layoutViews.weipo_offers_search = OffersSearchView;
  config.widgets.id.offer_language = TokenWidget;
  config.widgets.id.offer_keywords = TokenWidget;

  config.settings.contentIcons['Offer'] = cardSVG;
  config.settings.contentIcons['Umbrella Offer'] = cardRoofSVG;

  config.settings.appExtras = config.settings.appExtras.concat([
    {
      match: '',
      component: SidebarHelp,
    },
    {
      match: {
        path: '/de',
        exact: true,
      },
      component: Tooltips,
    },
    {
      match: '/de/angebot/**',
      component: Tooltips,
    },
  ]);

  config.addonRoutes.push({
    path: '/**/add_offer',
    component: AddOfferForm,
  });

  config.views.layoutViewsNamesMapping.weipo_offers_search =
    'WeiPo Offers Search';

  config.settings.image_crop_aspect_ratios = [
    {
      label: 'Header',
      ratio: 6 / 1,
    },
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '4:3',
      ratio: 4 / 3,
    },
    {
      label: '1:1',
      ratio: 1,
    },
  ];

  config.settings.slate.toolbarButtons = [
    'bold',
    'italic',
    'strikethrough',
    'link',
    'separator',
    'sub',
    'sup',
    'separator',
    'numbered-list',
    'bulleted-list',
    'styleMenu',
  ];

  config.settings.glossarytooltips_show_in_edit = true;

  config.settings.plausible = {
    dataDomain: 'weipo.uni-koblenz.de',
    src: 'https://plausible.uni-koblenz.de/js/script.js',
  };

  config.settings.shibboleth = {
    endpoint: 'https://weipo.uni-koblenz.de/Shibboleth.sso',
  };

  config.settings.fullcalendar = {
    additionalOptions: {
      /* custom range view to display ALL events */
      views: {
        listAll: {
          type: 'list',
          duration: { days: 9999 },
          buttonText: 'Alle',
          titleFormat: () => {
            return 'Alle';
          },
        },
      },
      /* display descriptions as Popups for calender entries,
         and in view type `listAll` manually link event titles
         (in grid views these are already linked)
      */
      eventContent: (eventInfo) => {
        const MAX_LEN = 500;
        const description =
          (eventInfo.event.extendedProps?.description || '').length > MAX_LEN
            ? eventInfo.event.extendedProps.description.slice(0, MAX_LEN) +
              ' ...'
            : eventInfo.event.extendedProps.description;

        const url = eventInfo.event.extendedProps?.['@id']
          ? flattenToAppURL(eventInfo.event.extendedProps?.['@id'])
          : false;

        return (
          <>
            <div className="fc-event-time">{eventInfo.timeText}</div>
            {description ? (
              <Popup
                content={description}
                trigger={
                  <ConditionalLink
                    to={url}
                    condition={
                      eventInfo.view.type === 'listAll' && url.length > 0
                    }
                  >
                    <div className="fc-event-title">
                      {eventInfo.event.title}
                    </div>
                  </ConditionalLink>
                }
                wide="very"
              />
            ) : (
              <ConditionalLink
                to={url}
                condition={eventInfo.view.type === 'listAll' && url.length > 0}
              >
                <div className="fc-event-title">{eventInfo.event.title}</div>
              </ConditionalLink>
            )}
          </>
        );
      },
    },
  };

  return config;
};

export default applyConfig;
