import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, List, Modal } from 'semantic-ui-react';
import {
  getSchema,
  getWorkflow,
  transitionWorkflow,
} from '@plone/volto/actions';
import { Icon, Toast } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import HideSVG from '@plone/volto/icons/hide.svg';
import PenSVG from '@plone/volto/icons/pen.svg';
import ShowSVG from '@plone/volto/icons/show.svg';

const WorkflowQuickDial = ({ content }) => {
  const [isClient, setIsClient] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [requiredFieldsToPublish, setRequiredFieldsToPublish] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsClient(true);
    dispatch(getSchema(content['@type']));
    dispatch(getWorkflow(location.pathname));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const schema = useSelector((state) => state.schema?.schema || false);

  const updateRequiredFields = () => {
    if (requiredFieldsToPublish === false) {
      fetch('/++api++/@get-offer-required-fields-to-publish')
        .then((response) => response.json())
        .then((data) => {
          setRequiredFieldsToPublish(data.required_to_publish || []);
        });
    }
  };
  if (isClient && document) {
    updateRequiredFields();
  }

  let missingRequiredFields = (requiredFieldsToPublish || []).filter(
    (fieldName) => {
      if (fieldName === 'teacher') {
        /* alternative field `teacher_external` */
        return !content.teacher && !content.teacher_external?.is_external;
      } else {
        return !content[fieldName];
      }
    },
  );
  if (schema?.properties) {
    missingRequiredFields = missingRequiredFields.map((fieldName) => {
      return {
        id: fieldName,
        label: schema.properties.hasOwnProperty(fieldName)
          ? schema.properties[fieldName].title
          : fieldName,
      };
    });
  }
  if (content['@type'] === 'Umbrella Offer') {
    if ((content.sub_offers || []).length < 2)
      missingRequiredFields.push({
        id: 'sub_offers',
        label: 'Teilangebote (mindestens 2)',
      });
    if (
      content.is_learning_pathway &&
      (content.creditability || []).length === 0
    )
      missingRequiredFields.push({
        id: 'creditability',
        label: 'Kompetenznachweis',
      });
  }

  const transition = useSelector((state) => state.workflow.transition);
  if (transition.error) {
    toast.error(
      <Toast success title="Fehler" content={transition.error.toString()} />,
    );
  }
  if (transition.loaded === true) {
    if (transition.error) {
      toast.error(
        <Toast success title="Fehler" content={transition.error.toString()} />,
      );
    } else {
      window.location = location.pathname;
    }
  }

  const transitions = useSelector(
    (state) => state.workflow.transitions,
    shallowEqual,
  );
  const actionPublish = transitions.find((t) => t['@id'].endsWith('/publish'));
  const actionRetract = transitions.find((t) => t['@id'].endsWith('/retract'));

  const doAction = (action) => {
    if (
      action['@id'].endsWith('/publish') &&
      missingRequiredFields.length > 0
    ) {
      setModalOpen(true);
      return;
    }
    if (transition.loading === false) {
      dispatch(transitionWorkflow(flattenToAppURL(action['@id'])));
    }
  };

  const getFieldsetOfField = (fieldName) => {
    // @TODO: implement (use schema)
    if (!schema || !schema.fieldsets) return '';
    const fieldset = schema.fieldsets.find(
      (fieldset) => fieldset.fields.indexOf(fieldName) > -1,
    );
    return fieldset ? fieldset.title || '' : '';
  };

  return (
    <>
      {actionPublish && (
        <button
          onClick={() => {
            doAction(actionPublish);
          }}
          style={{ color: '#007eb1' }}
        >
          <Icon
            name={ShowSVG}
            className="circled"
            title="Jetzt veröffentlichen"
          />
        </button>
      )}
      {actionRetract && (
        <button
          onClick={() => {
            doAction(actionRetract);
          }}
          style={{ color: '#e40166' }}
        >
          <Icon name={HideSVG} className="circled" title="Jetzt zurückziehen" />
        </button>
      )}

      <Modal
        closeIcon
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
      >
        <Modal.Header>Fehlende Angaben</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Folgende Felder müssen noch ausgefüllt werden, damit die
            Angebotsbeschreibung veröffentlicht werden kann:
            <br />
            <List bulleted>
              {missingRequiredFields.map((field) => (
                <List.Item key={field.id}>
                  {getFieldsetOfField(field.id)} {'> '}
                  <b className="required-to-publish-marker">{field.label}</b>
                </List.Item>
              ))}
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button icon href={location.pathname + '/edit'} color="blue">
            <Icon name={PenSVG} style={{ height: '18px' }} /> Bearbeiten
          </Button>
          <Button negative onClick={() => setModalOpen(false)}>
            Schließen
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default WorkflowQuickDial;
