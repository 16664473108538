import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { getContent } from '@plone/volto/actions';
import { ConditionalLink, Icon as VoltoIcon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import placeholderJPG from '@package/assets/placeholder_project.jpg';
import placeholderWEBP from '@package/assets/placeholder_project.webp';
import cardRoofSVG from './../../svgs/icons/card_roof_11.svg';
import digCompEduSVG from './../../svgs/icons/digcompedu.svg';
import './card.less';

const truncate = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  const lastSpaceIndex = text.lastIndexOf(' ', maxLength);
  const truncatedText = text.substring(0, lastSpaceIndex) + ' ...';
  return truncatedText;
};

const OfferCard = ({
  content,
  linkDisabled,
  compact = false,
  slot = false,
  rehydrate = false,
}) => {
  /* rehydrate content if requested */
  const dispatch = useDispatch();
  const subRequestKey = flattenToAppURL(content['@id']);
  const subRequest = useSelector(
    (state) => state.content.subrequests?.[subRequestKey] || false,
  );
  useEffect(() => {
    if (
      /* always rehydrate Umbrella Offers because of the sub_offers */
      (rehydrate || content['@type'] === 'Umbrella Offer') &&
      (subRequest === false || !subRequest.loaded)
    ) {
      dispatch(getContent(subRequestKey, null, subRequestKey));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const image =
    flattenToAppURL(subRequest.data?.image_card?.scales?.great?.download) ||
    flattenToAppURL(subRequest.data?.image_card?.download) ||
    flattenToAppURL(subRequest.data?.image_header?.scales?.great?.download) ||
    flattenToAppURL(subRequest.data?.image_header?.download) ||
    content.image_scales?.image_card?.[0]?.scales?.great?.download ||
    content.image_scales?.image_card?.[0]?.download ||
    content.image_scales?.image_header?.[0]?.scales?.great?.download ||
    content.image_scales?.image_header?.[0]?.download ||
    false;

  const expired = content.period_to
    ? new Date() > new Date(content.period_to)
    : false;

  const usesDigCompEdu =
    Object.keys(content.competences || {}).length > 0 ||
    /* also check if potential sub offers use DigCompEdu */
    (subRequest?.data?.sub_offers || [])
      .filter((o) => o && o['@id'])
      .find((so) => Object.keys(so.competences || {}).length > 0);
  return (
    <div className="offer-card">
      <ConditionalLink
        to={flattenToAppURL(content['@id'])}
        condition={!linkDisabled}
        className="no-deco"
      >
        <div className="image">
          {image ? (
            <img src={flattenToAppURL(content['@id'] + '/' + image)} alt="" />
          ) : (
            <picture>
              <source type="image/webp" srcSet={placeholderWEBP} />
              <img src={placeholderJPG} alt="" />
            </picture>
          )}
        </div>
        {slot && slot}
        {content.review_state === 'draft' ? (
          <div className="meta" title="Noch nicht veröffentlichter Entwurf">
            <i>Entwurf</i>
          </div>
        ) : (
          expired && (
            <div className="meta" title="Das Angebot hat bereits stattgefunden">
              <i>Abgelaufen</i>
            </div>
          )
        )}
        <div className="title awh">{content.short_title || content.title}</div>
        {!compact && content.description && (
          <div className="description">
            {truncate(content.description, 300)}
            <br />
            <br />
            <span className="cta-link">
              <span>Weiterlesen</span>
            </span>
          </div>
        )}
      </ConditionalLink>
      {!compact && (
        <div className="footer">
          {content['@type'] === 'Umbrella Offer' ? (
            <VoltoIcon
              name={cardRoofSVG}
              style={{ height: '31px', marginTop: '2px' }}
              title="Dies ist ein Dachangebot"
            />
          ) : (
            <VoltoIcon
              name={cardRoofSVG}
              style={{ height: '31px', marginTop: '2px' }}
              className="disabled"
            />
          )}
          {usesDigCompEdu && (
            <VoltoIcon
              style={{ height: '35px' }}
              name={digCompEduSVG}
              title="Kompetenzrahmen DigCompEdu"
            />
          )}
          {content.link ? (
            <a
              href={content.link}
              target="_blank"
              rel="noreferrer"
              title="Direkt zum Angebot"
            >
              <Icon
                name="linkify"
                style={{ lineHeight: '35px', fontSize: '31px' }}
              />
            </a>
          ) : (
            <Icon
              name="linkify"
              size="large"
              className="disabled"
              style={{ lineHeight: '35px', fontSize: '31px' }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OfferCard;
