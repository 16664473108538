/* Wrapper around the original component to transform offers into events.
   (One offer can have multiple dates.)   
*/
// eslint-disable-next-line import/no-unresolved
import FullCalendarListingOrig from '@mbarde/volto-fullcalendar-block-original/components/manage/Blocks/Listing/FullCalendar';

const FullCalendarListing = ({ items, ...props }) => {
  const allEvents = items.flatMap((item) => {
    if (item['@type'] !== 'Offer') return item;
    let itemEvents = item.offer_dates.items
      .filter((od) => od.start && od.end)
      .map((od) => {
        return {
          '@id': item['@id'],
          title: item.title,
          description: item.description,
          start: od.start,
          end: od.end,
        };
      });
    return itemEvents;
  });
  return <FullCalendarListingOrig items={allEvents} {...props} />;
};

export default FullCalendarListing;
