import React from 'react';
import { defineMessages } from 'react-intl';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  addItem: {
    id: 'Add date',
    defaultMessage: 'Add date',
  },
  start: {
    id: 'Start',
    defaultMessage: 'Start',
  },
  end: {
    id: 'End',
    defaultMessage: 'End',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    properties: {
      start: {
        title: intl.formatMessage(messages.start),
        widget: 'datetime',
        widgetOptions: {},
      },
      end: {
        title: intl.formatMessage(messages.end),
        widget: 'datetime',
        widgetOptions: {},
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.item),
        fields: ['start', 'end'],
      },
    ],
    required: ['start', 'end'],
  };
};

const Widget = (props) => {
  const itemSchema = ItemSchema(props.intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default Widget;
