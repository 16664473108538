import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Header,
  Icon,
  Input,
  List,
  Progress,
  Segment,
  Table,
} from 'semantic-ui-react';
import cx from 'classnames';
import { getContent } from '@plone/volto/actions';
import './style.less';

const getCompetenceById = (id, schema) => {
  for (let a = 0; a < schema.length; a++) {
    const area = schema[a];
    if (id.startsWith(area.id)) {
      for (let c = 0; c < area.children.length; c++) {
        const competence = area.children[c];
        if (competence.id === id) {
          return [area, competence];
        }
      }
    }
  }
  return [false, false];
};

const Competence = ({ id, schema, selectedLevels, setSelectedLevels }) => {
  const [area, competence] = getCompetenceById(id, schema);
  if (!competence) return <span>Competence with ID {id} not found.</span>;

  const onChangeBadge = (evt, data) => {
    if (!selectedLevels.hasOwnProperty(id)) {
      selectedLevels[id] = { badge: data.checked };
    } else if (selectedLevels[id].badge !== data.checked) {
      selectedLevels[id].badge = data.checked;
    }
    setSelectedLevels(selectedLevels);
  };

  const onChangeCriteria = (criteria) => {
    if (!selectedLevels.hasOwnProperty(id)) {
      selectedLevels[id] = { criteria: criteria };
    } else {
      selectedLevels[id].criteria = criteria;
    }
    setSelectedLevels(selectedLevels);
  };

  const onChangeCriterion = (id, value) => {
    let cs = criteria;
    while (cs.length <= id) {
      cs.push('');
    }
    cs[id] = value.trimStart();
    onChangeCriteria(cs);
  };

  const getNextLevel = (levelKey) => {
    const levels = Object.keys(competence.levels);
    const idx = levels.indexOf(levelKey);
    if (idx < 0) return levelKey;
    if (idx >= levels.length - 1) return levels[levels.length - 1];
    return levels[idx + 1];
  };

  const criteria = selectedLevels[id]?.criteria || [];

  return (
    <>
      <b style={{ color: area.color }}>{area.label}</b>
      <h3 style={{ color: area.color, marginTop: '0' }}>
        {competence.id} {competence.label}
      </h3>
      <Checkbox
        label="Für diese Kompetenz kann eine anrechenbare Evidenz bescheinigt werden."
        checked={selectedLevels[id]?.badge === true}
        onChange={onChangeBadge}
      />
      <Table className="levels" selection verticalAlign="middle" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Level</Table.HeaderCell>
            <Table.HeaderCell>Wird vorausgesetzt</Table.HeaderCell>
            <Table.HeaderCell>Wird erworben</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {Object.keys(competence.levels).map((key) => {
          const level = competence.levels[key];
          return (
            <Table.Row
              className={cx({ selected: selectedLevels[id] === key })}
              key={`level-${key}`}
            >
              <Table.Cell>
                <Header as="h4">{level.label}</Header>
                <List.Description>{level.description}</List.Description>
                <List.Description>
                  <i>{level.statement}</i>
                </List.Description>
              </Table.Cell>
              <Table.Cell>
                {selectedLevels[id]?.from === key ? (
                  <Icon name="check circle" size="big" />
                ) : key === 'c2' ? (
                  <Icon
                    name="dont"
                    size="big"
                    color="grey"
                    disabled
                    title="Das zu erwerbende Kompetenzlevel muss über der Voraussetzung liegen."
                  />
                ) : (
                  <Icon
                    name="circle outline"
                    size="big"
                    color="grey"
                    onClick={() => {
                      const from = key;
                      const to = selectedLevels[id]?.to
                        ? selectedLevels[id]?.to <= from
                          ? getNextLevel(from)
                          : selectedLevels[id]?.to
                        : getNextLevel(from);
                      setSelectedLevels({
                        ...selectedLevels,
                        [id]: { ...selectedLevels[id], from: from, to: to },
                      });
                    }}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                {selectedLevels[id]?.to === key ? (
                  <Icon name="check circle" size="big" />
                ) : selectedLevels[id]?.from <= key ? (
                  <Icon
                    name="circle outline"
                    size="big"
                    color="grey"
                    onClick={() =>
                      setSelectedLevels({
                        ...selectedLevels,
                        [id]: { ...selectedLevels[id], to: key },
                      })
                    }
                  />
                ) : (
                  <Icon
                    name="dont"
                    size="big"
                    color="grey"
                    disabled
                    title="Das zu erwerbende Kompetenzlevel muss über der Voraussetzung liegen."
                  />
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>

      <h3>Kriterien</h3>
      <p>
        Bitte geben Sie im Folgenden mindestens ein trennscharfes Kriterium an,
        das aufzeigt, wie Sie die Kompetenz auf der angegebenen Zielstufe
        adressieren wollen.
      </p>
      <Input
        placeholder="1. Kriterium"
        title="1. Kriterium - Eingabe erforderlich"
        fluid
        label={{ icon: 'asterisk' }}
        labelPosition="right corner"
        onChange={(evt) => {
          onChangeCriterion(0, evt.target.value);
        }}
        value={criteria.length > 0 ? criteria[0] : ''}
      />
      <br />
      {criteria.length > 0 && criteria[0].length > 0 && (
        <>
          <Input
            placeholder="2. Kriterium"
            title="2. Kriterium"
            fluid
            onChange={(evt) => {
              onChangeCriterion(1, evt.target.value);
            }}
            value={criteria.length > 1 ? criteria[1] : ''}
          />
          <br />
        </>
      )}
      {criteria.length > 1 && criteria[1].length > 0 && (
        <>
          <Input
            placeholder="3. Kriterium"
            title="3. Kriterium - optional"
            fluid
            onChange={(evt) => {
              onChangeCriterion(2, evt.target.value);
            }}
            value={criteria.length > 2 ? criteria[2] : ''}
          />
          <br />
        </>
      )}
      {criteria.length > 2 && criteria[2].length > 0 && (
        <>
          <Input
            placeholder="4. Kriterium"
            title="4. Kriterium - optional"
            fluid
            onChange={(evt) => {
              onChangeCriterion(3, evt.target.value);
            }}
            value={criteria.length > 3 ? criteria[3] : ''}
          />
          <br />
        </>
      )}
    </>
  );
};

const SelectCompetences = ({
  selectedComptences,
  onChangeCompetences,
  onNext,
  schema,
}) => {
  return (
    <>
      <h2>Kompetenzen auswählen</h2>
      <p>
        Wählen Sie hier die Kompetenzen aus, die in diesem Angebot gezielt
        gesteigert oder verfestigt werden.
      </p>
      {schema.map((area) => {
        return (
          <div key={`area-${area.id}`}>
            <h3 style={{ color: area.color }}>{area.label}</h3>
            {area.children.map((competence) => {
              return (
                <>
                  <Checkbox
                    value={competence.id}
                    label={`${competence.id} ${competence.label}`}
                    checked={selectedComptences.indexOf(competence.id) > -1}
                    onChange={onChangeCompetences}
                  />
                  <br />
                </>
              );
            })}
          </div>
        );
      })}
      <Button
        color="green"
        id="btn-next-digcompedu"
        disabled={selectedComptences.length === 0}
        onClick={(e) => {
          onNext();
          e.preventDefault();
        }}
      >
        Weiter
        <Icon name="arrow right" />
      </Button>
    </>
  );
};

const DigCompEduWidget = ({ id, onChange, value }) => {
  const [step, setStep] = useState(0);
  const [selectedComptences, setSelectedComptences] = useState(
    value ? Object.keys(value) : [],
  );
  const [selectedLevels, setSelectedLevels] = useState(value);

  const url = '/de/digcompedu-schema';
  const subrequest = useSelector((state) => state.content.subrequests?.[url]);
  const schema = subrequest?.data?.schema?.items || false;
  const dispatch = useDispatch();
  useEffect(() => {
    if (url !== false && subrequest?.loaded !== true)
      dispatch(getContent(url, null, url));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  const onChangeCompetences = (evt, data) => {
    if (selectedComptences.indexOf(data.value) > -1) {
      /* competence has been de-selected */
      const selectedComptencesNew = selectedComptences.filter(
        (a) => a !== data.value,
      );
      setSelectedComptences(selectedComptencesNew);
      const newSelectedLevels = Object.fromEntries(
        Object.entries(selectedLevels).filter(
          ([key]) => selectedComptencesNew.indexOf(key) > -1,
        ),
      );
      setSelectedLevels(newSelectedLevels);
      onChange(id, newSelectedLevels);
    } else {
      setSelectedComptences(selectedComptences.concat([data.value]).sort());
      /* if new competence was selected, add empty levels dataset to make
         sure selection is stored even if user did not yet select levels
      */
      if (Object.keys(selectedLevels).indexOf(data.value) === -1) {
        const newSelectedLevels = {
          ...selectedLevels,
          [data.value]: { criteria: [] },
        };
        setSelectedLevels(newSelectedLevels);
        onChange(id, newSelectedLevels);
      }
    }
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const switchFieldset = (forward = true) => {
    const kid = document.querySelector(
      '.ui.form .ui.secondary.attached.pointing.menu .active.item',
    );
    if (kid) {
      const kidId = Array.from(kid.parentElement.children).indexOf(kid);
      const nextId = forward ? kidId + 2 : kidId;
      const nextEl = document.querySelector(
        `.ui.form .ui.secondary.attached.pointing.menu .item:nth-child(${nextId})`,
      );
      if (nextEl) nextEl.click();
      scrollToTop();
    }
  };

  return (
    <Segment>
      {step === 0 && schema && (
        <>
          <Progress percent={0} size="tiny"></Progress>
          <SelectCompetences
            selectedComptences={selectedComptences}
            onChangeCompetences={onChangeCompetences}
            onNext={() => setStep(1)}
            schema={schema}
          />
          <Button
            id="btn-prev-digcompedu"
            onClick={(e) => {
              switchFieldset(false);
            }}
          >
            Zurück
          </Button>
        </>
      )}
      {step > 0 && step - 1 < selectedComptences.length && (
        <>
          <Progress
            percent={(step / selectedComptences.length) * 100}
            size="tiny"
          ></Progress>
          <Competence
            id={selectedComptences[step - 1]}
            selectedLevels={selectedLevels}
            setSelectedLevels={(value) => {
              onChange(id, value);
              setSelectedLevels(value);
            }}
            schema={schema}
          />
          <Button
            id="btn-prev-digcompedu"
            onClick={(e) => {
              setStep(step - 1);
              scrollToTop();
              e.preventDefault();
            }}
          >
            Zurück
          </Button>
          <Button
            color="green"
            id="btn-next-digcompedu"
            onClick={(e) => {
              setStep(step + 1);
              scrollToTop();
              e.preventDefault();
            }}
            disabled={
              selectedLevels[selectedComptences[step - 1]] === undefined ||
              !selectedLevels[selectedComptences[step - 1]].hasOwnProperty(
                'from',
              ) ||
              !selectedLevels[selectedComptences[step - 1]].hasOwnProperty(
                'to',
              ) ||
              !selectedLevels[selectedComptences[step - 1]].hasOwnProperty(
                'criteria',
              ) ||
              selectedLevels[selectedComptences[step - 1]].criteria.length <
                1 ||
              selectedLevels[selectedComptences[step - 1]].criteria[0]
                .length === 0
            }
          >
            Weiter
            <Icon name="arrow right" />
          </Button>
        </>
      )}
      {step > selectedComptences.length && (
        <>
          <Progress percent={100} size="tiny" color="green"></Progress>
          <h2>
            Angesprochene Kompetenzen <Icon name="check" />
          </h2>
          <p>
            Sie haben den Reiter zu den angesprochenen Kompetenzen vollständig
            bearbeitet.
          </p>
          <Button
            id="btn-prev-digcompedu"
            floated="left"
            onClick={(e) => {
              setStep(step - 1);
              scrollToTop();
              e.preventDefault();
            }}
          >
            Zurück
          </Button>
          <Button
            color="green"
            id="btn-next-digcompedu"
            onClick={(e) => {
              switchFieldset(true);
            }}
          >
            Formular weiter bearbeiten
            <Icon name="arrow right" />
          </Button>
        </>
      )}
    </Segment>
  );
};

export default DigCompEduWidget;
